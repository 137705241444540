<template>
  <b-card
    v-if="data"
    class="card-statistics"
  >
    <b-card-header>
      <b-card-title>Statistiques</b-card-title>
    </b-card-header>
    <b-row class="justify-content-center mx-1">
      <b-col
        md="6"
      >
        <b-form-group>
          <h5>Filtrer par date / période</h5>
          <flat-pickr
            v-model="rangeDate"
            class="form-control"
            :config="config"
          />
        </b-form-group>
      </b-col>
      <b-col
        md="3"
        class="mt-2"
      >
        <b-button
          :disabled="isRangeStatisticsLoading"
          variant="primary"
          @click="
            rangeDate = null;
            filterIsActive = false;
          "
        >
          {{ isRangeStatisticsLoading ? "Chargement..." : "Effacer" }}
        </b-button>
      </b-col>
    </b-row>
    <b-card-body class="statistics-body">
      <b-row>
        <b-col
          v-for="(item , index) in Object.entries(statsData)"
          :key="index"
          lg="4"
          sm="6"
          style="height:max-content !important;"
        >

          <statistic-card-horizontal
            :icon="
              item[0]==='numberOfActiveContracts' ? 'PowerIcon':
              item[0]==='numberOfCompletedPunctualOrder' ? 'CheckIcon':
              item[0]==='numberOfEmployeesDeployed' ? 'UserIcon':
              item[0]==='numberOfFinishedContract' ? 'ShieldOffIcon':
              item[0]==='numberOfPunctualOrder' ? 'RotateCwIcon':
              item[0]==='numberOfRecurringOrderBroughtByCC' ? 'RepeatIcon':
              item[0]==='numberOfRegisteredCustomers' ? 'UsersIcon':
              item[0]==='numberOfRegisteredPros' ? 'UsersIcon':
              item[0]==='numberOfSuspendedContract' ? 'DeleteIcon':
              item[0]==='numberOfEmployeesRegistered' ? 'UserIcon':
              item[0]==='numberOfEmployeesValidated' ? 'UserCheckIcon':
              item[0]==='numberOfEmployeesWhoQuitTheirJobs'? 'UserMinusIcon' :
              item[0]==='numberOfRecurringOrder'? 'RepeatIcon':
              ''"
            color="info"
            :statistic="item[1].toLocaleString()"
            :statistic-title="
              item[0]==='numberOfActiveContracts' ? 'Contrat(s) actif(s)':
              item[0]==='numberOfCompletedPunctualOrder' ? 'Commande(s) ponctuelle(s) terminée(s) ':
              item[0]==='numberOfEmployeesDeployed' ? 'Employé(s) déployé(s)':
              item[0]==='numberOfFinishedContract' ? 'Contrat(s) clôturé(s)':
              item[0]==='numberOfPunctualOrder' ? 'Commande(s) ponctuelle(s)':
              item[0]==='numberOfRecurringOrderBroughtByCC' ? 'Commande(s) récurrente(s) de la CC':
              item[0]==='numberOfRegisteredCustomers' ? 'Client(s) inscrit(s)':
              item[0]==='numberOfRegisteredPros' ? 'Pro(s) inscrit(s)':
              item[0]==='numberOfSuspendedContract' ? 'Contrat(s) résilié(s)':
              item[0]==='numberOfEmployeesRegistered' ? 'Employé(s) enregistré(s)':
              item[0]==='numberOfEmployeesValidated' ? 'Employé(s) validé(s)':
              item[0]==='numberOfEmployeesWhoQuitTheirJobs'? 'Employé(s) désisté(s)' :
              item[0]==='numberOfRecurringOrder'? 'Commande(s) récurrente(s)':
              ''"
          />
        </b-col>

        <b-col
          v-for="item in statsData"
          :key="item.subtitle"
          xl="4"
          lg="4"
          md="4"
          sm="6"
          cols="12"
          :class="item.customClass"
        >
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar
                size="48"
                :variant="item.color"
              >
                <feather-icon
                  size="24"
                  :icon="item.icon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder font-medium-4 mb-0">
                {{ item.title }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ item.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BButton,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BFormGroup,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    flatPickr,
    BMediaBody,
    BFormGroup,
    StatisticCardHorizontal,
  },

  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      config: { mode: 'range' },
      rangeDate: null,
      filterIsActive: false,
      isRangeStatisticsLoading: false,
    }
  },

  computed: {
    ...mapGetters('statistics', [
      'getSatisfiedOrder',
      'getUnsatisfiedOrder',
    ]),

    ...mapGetters('auth', ['getUser']),

    ...mapGetters('services', [
      'getServices',
      'getRecurringServices',
    ]),

    ...mapGetters('professionals', [
      'getProfessionals',
    ]),

    statsData() {
      if (this.filterIsActive) {
        return this.filteredData
      }
      return this.$props.data
    },

    filteredData() {
      let range = null
      const user = JSON.parse(localStorage.getItem('user'))
      if (user.role.name === 'Super Administrateur') {
        range = this.rangeData
      }
      if (user.role.name === "Chargé d'affaires") {
        range = this.businessManagerRangeData
      }
      if (user.role.name === 'Chargé de Clientèle') {
        range = this.customerServiceManagerRangeData
      }
      if (user.role.name === 'Business developer Pro') {
        range = this.proBusinessDeveloperRangeData
      }
      return range
    },

    rangeData() {
      return [
        {
          icon: 'UsersIcon',
          color: 'light-primary',
          title: '',
          subtitle: 'Clients',
          customClass: 'mb-2 mb-xl-2',
        },
        {
          icon: 'UserCheckIcon',
          color: 'light-success',
          title: '',
          subtitle: 'Professionnels',
          customClass: 'mb-2 mb-xl-2',
        },
        {
          icon: 'UserPlusIcon',
          color: 'light-success',
          title: '',
          subtitle: 'Employés inscrits',
          customClass: 'mb-2 mb-xl-2',
        },
        {
          icon: 'UserCheckIcon',
          color: 'light-success',
          title: '',
          subtitle: 'Employés validés',
          customClass: 'mb-2 mb-xl-2',
        },
        {
          icon: 'HomeIcon',
          color: 'light-success',
          title: '',
          subtitle: 'Employés déployés',
          customClass: 'mb-2 mb-xl-2',
        },
        {
          icon: 'UserXIcon',
          color: 'light-danger',
          title: '',
          subtitle: 'Employés ayant désisté',
          customClass: 'mb-2 mb-xl-2',
        },
        {
          icon: 'BookmarkIcon',
          color: 'light-info',
          title: '',
          subtitle: 'Commandes reçues',
          customClass: 'mb-2 mb-xl-2',
        },
        {
          icon: 'CheckIcon',
          color: 'light-success',
          title: '',
          subtitle: 'Commandes terminées',
          customClass: 'mb-2 mb-xl-2',
        },
        {
          icon: 'ThumbsUpIcon',
          color: 'light-primary',
          title: '',
          subtitle: 'Commandes satisfaites',
          customClass: 'mb-2 mb-xl-2',
        },
        {
          icon: 'ThumbsDownIcon',
          color: 'light-danger',
          title: '',
          subtitle: 'Commandes insatisfaites',
          customClass: 'mb-2 mb-xl-2',
        },
        {
          icon: 'BookmarkIcon',
          color: 'light-info',
          title: '',
          subtitle: 'Commandes récurrentes',
          customClass: 'mb-2 mb-xl-2',
        },
        {
          icon: 'PowerIcon',
          color: 'light-success',
          title: '',
          subtitle: 'Contrats actifs',
          customClass: 'mb-2 mb-xl-2',
        },
        {
          icon: 'XCircleIcon',
          color: 'light-danger',
          title: '',
          subtitle: 'Contrats résiliés',
          customClass: 'mb-2 mb-xl-2',
        },
        {
          icon: 'CheckIcon',
          color: 'light-success',
          title: '',
          subtitle: 'Commandes récurrentes clôturés',
          customClass: 'mb-2 mb-xl-2',
        },
        {
          icon: 'UsersIcon',
          color: 'light-success',
          title: '',
          subtitle: 'Commandes récurrentes apporté par la CC',
          customClass: 'mb-2 mb-xl-2',
        },
      ]
    },

    businessManagerRangeData() {
      return [
        {
          icon: 'FileTextIcon',
          color: 'light-primary',
          title: '',
          subtitle: 'Contrats actifs',
          customClass: 'mb-2 mb-xl-2',
        },
        {
          icon: 'FileTextIcon',
          color: 'light-danger',
          title: '',
          subtitle: 'Contrats résiliés',
          customClass: 'mb-2 mb-xl-2',
        },
        {
          icon: 'DollarSignIcon',
          color: 'light-info',
          title: '',
          subtitle: 'Commissions',
          customClass: 'mb-2 mb-xl-2',
        },
        {
          icon: 'TrendingUpIcon',
          color: 'light-success',
          title: '',
          subtitle: "Croissance du chiffre d'affaire",
          customClass: 'mb-2 mb-xl-2',
        },
        {
          icon: 'UsersIcon',
          color: 'light-primary',
          title: '',
          subtitle: 'Clients invités',
          customClass: 'mb-2 mb-xl-2',
        },
        {
          icon: 'FolderIcon',
          color: 'light-success',
          title: '',
          subtitle: 'Commandes récurrentes en charge',
          customClass: 'mb-2 mb-xl-2',
        },
      ]
    },

    customerServiceManagerRangeData() {
      return [
        {
          icon: 'UsersIcon',
          color: 'light-primary',
          title: '',
          subtitle: 'Clients invités ayant passé une Commandes récurrentes',
          customClass: 'mb-2 mb-xl-2',
        },
        {
          icon: 'CheckSquareIcon',
          color: 'light-danger',
          title: '',
          subtitle: 'Commandes ponctuelles gérées',
          customClass: 'mb-2 mb-xl-2',
        },
        {
          icon: 'DollarSignIcon',
          color: 'light-success',
          title: '',
          subtitle: 'Commissions des commandes ponctuelles',
          customClass: 'mb-2 mb-xl-2',
        },
      ]
    },

    proBusinessDeveloperRangeData() {
      return [
        {
          icon: 'UserPlusIcon',
          color: 'light-info',
          title: '',
          subtitle: 'Employés inscrits',
          customClass: 'mb-2 mb-xl-2',
        },
        {
          icon: 'UserCheckIcon',
          color: 'light-success',
          title: '',
          subtitle: 'Employés validés',
          customClass: 'mb-2 mb-xl-2',
        },
        {
          icon: 'UserXIcon',
          color: 'light-danger',
          title: '',
          subtitle: 'Employés ayant désisté',
          customClass: 'mb-2 mb-xl-2',
        },
        {
          icon: 'NavigationIcon',
          color: 'light-success',
          title: '',
          subtitle: 'Employés déployés',
          customClass: 'mb-2 mb-xl-2',
        },
        {
          icon: 'UserPlusIcon',
          color: 'light-info',
          title: '',
          subtitle: 'Pros inscrits',
          customClass: 'mb-2 mb-xl-2',
        },
        {
          icon: 'UserCheckIcon',
          color: 'light-success',
          title: '',
          subtitle: 'Pros validés',
          customClass: 'mb-2 mb-xl-2',
        },
      ]
    },
  },

  watch: {
    rangeDate(val) {
      if (val.split('to').length === 2) {
        this.applyGetRangeStatisticsAction(val)
      }
    },
  },

  methods: {
    ...mapActions('statistics', ['getRangeStatisticsAction']),

    applyGetRangeStatisticsAction(val) {
      const payload = {
        start_date: val.split('to')[0],
        end_date: val.split('to')[1],
      }
      const user = JSON.parse(localStorage.getItem('user'))
      this.isRangeStatisticsLoading = true
      this.getRangeStatisticsAction(payload)
        .then(response => {
          this.isRangeStatisticsLoading = false
          if (user.role.name === 'Super Administrateur') {
            this.rangeData.forEach(element => {
              switch (element.subtitle) {
                case 'Clients':
                  // eslint-disable-next-line no-param-reassign
                  element.title = response.data.customerDiffPerPeriod
                  break
                case 'Professionnels':
                  // eslint-disable-next-line no-param-reassign
                  element.title = this.getProfessionals.length
                  break
                case 'Employés inscrits':
                  // eslint-disable-next-line no-param-reassign
                  element.title = response.data.numberOfEmployeesRegisteredPerPeriod
                  break
                case 'Employés validés':
                  // eslint-disable-next-line no-param-reassign
                  element.title = response.data.numberOfEmployeesValidatedPerPeriod
                  break
                case 'Employés déployés':
                  // eslint-disable-next-line no-param-reassign
                  element.title = response.data.numberOfEmployeesDeployedPerPeriod
                  break
                case 'Employés ayant désisté':
                  // eslint-disable-next-line no-param-reassign
                  element.title = response.data.numberOfEmployeesWhoHaveWithdrawnFromWorkPerPeriod
                  break
                case 'Commandes reçues':
                  // eslint-disable-next-line no-param-reassign
                  element.title = response.data.orderDiffPerPeriod
                  break
                case 'Commandes terminées':
                  // eslint-disable-next-line no-param-reassign
                  element.title = response.data.orderCompletedDiff
                  break
                case 'Commandes satisfaites':
                  // eslint-disable-next-line no-param-reassign
                  element.title = this.getSatisfiedOrder
                  break
                case 'Commandes insatisfaites':
                  // eslint-disable-next-line no-param-reassign
                  element.title = this.getUnsatisfiedOrder
                  break
                case 'Commandes récurrentes':
                  // eslint-disable-next-line no-param-reassign
                  element.title = response.data.numberOfNewRecurringOrderPerPeriodOverall
                  break
                case 'Contrats actifs':
                  // eslint-disable-next-line no-param-reassign
                  element.title = response.data.numberOfActiveContractsOverall
                  break
                case 'Contrats résiliés':
                  // eslint-disable-next-line no-param-reassign
                  element.title = response.data.numberOfTerminatedRecurringOrderByPeriodOverall
                  break
                case 'Commandes récurrentes clôturés':
                  // eslint-disable-next-line no-param-reassign
                  element.title = response.data.numberOfCompletedRecurringOrderByPeriodOverall
                  break
                case 'Commandes récurrentes apporté par la CC':
                  // eslint-disable-next-line no-param-reassign
                  element.title = response.data.totalNumberOfInvitedCustomersWhoPlacedARecurringOrder
                  break

                default:
                  break
              }
            })
            // console.log(this.rangeData)
          }
          if (user.role.name === "Chargé d'affaires") {
            this.businessManagerRangeData.forEach(element => {
              switch (element.subtitle) {
                case 'Contrats actifs':
                  // eslint-disable-next-line no-param-reassign
                  element.title = response.data.numberOfActiveContractsCA
                  break
                case 'Contrats résiliés':
                  // eslint-disable-next-line no-param-reassign
                  element.title = response.data.numberOfTerminatedContracts
                  break
                case 'Commissions':
                  // eslint-disable-next-line no-param-reassign
                  element.title = response.data.totalAmountOfCommissionCA
                  break
                case "Croissance du chiffre d'affaire":
                  // eslint-disable-next-line no-param-reassign
                  element.title = response.data.turnoverGrowthRate
                  break
                case 'Clients invités':
                  // eslint-disable-next-line no-param-reassign
                  element.title = response.data.totalNumberOfInvitedCustomersCA
                  break
                case 'Commandes récurrentes en charge':
                  // eslint-disable-next-line no-param-reassign
                  element.title = response.data.totalNumberOfOrdersInChargeForCA
                  break
                default:
                  break
              }
            })
            // console.log(this.businessManagerRangeData)
          }
          if (user.role.name === 'Chargé de Clientèle') {
            this.customerServiceManagerRangeData.forEach(element => {
              switch (element.icon) {
                case 'UsersIcon':
                  // eslint-disable-next-line no-param-reassign
                  element.title = response.data.totalNumberOfInvitedCustomersWhoPlacedARecurringOrder
                  break
                case 'CheckSquareIcon':
                  // eslint-disable-next-line no-param-reassign
                  element.title = response.data.totalNumberOfPonctualOrdersManagedCompleted
                  break
                case 'DollarSignIcon':
                  // eslint-disable-next-line no-param-reassign
                  element.title = response.data.totalCommissionAmountOfPonctualOrders
                  break
                default:
                  break
              }
            })
          }
          if (user.role.name === 'Business developer Pro') {
            this.proBusinessDeveloperRangeData.forEach(element => {
              switch (element.subtitle) {
                case 'Employés inscrits':
                  // eslint-disable-next-line no-param-reassign
                  element.title = response.data.numberOfEmployeesRegisteredPerPeriod
                  break
                case 'Employés validés':
                  // eslint-disable-next-line no-param-reassign
                  element.title = response.data.numberOfEmployeesValidatedPerPeriod
                  break
                case 'Employés ayant désisté':
                  // eslint-disable-next-line no-param-reassign
                  element.title = response.data.numberOfEmployeesWhoHaveWithdrawnFromWorkPerPeriod
                  break
                case 'Employés déployés':
                  // eslint-disable-next-line no-param-reassign
                  element.title = response.data.numberOfEmployeesDeployedPerPeriod
                  break
                case 'Pros inscrits':
                  // eslint-disable-next-line no-param-reassign
                  element.title = response.data.numberOfProRegisteredPerPeriod
                  break
                case 'Pros validés':
                  // eslint-disable-next-line no-param-reassign
                  element.title = response.data.numberOfProValidatedPerPeriod
                  break
                default:
                  break
              }
            })
          }
          this.filterIsActive = true
        })
        .catch(error => {
          this.isRangeStatisticsLoading = false
          if (error.response) {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Erreur',
                  icon: 'AlertTriangleIcon',
                  text: error.response.data.message,
                  variant: 'danger',
                },
              },
              {
                position: 'top-center',
              },
            )
          }
        })
    },
  },

}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
