var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.data)?_c('b-card',{staticClass:"card-statistics"},[_c('b-card-header',[_c('b-card-title',[_vm._v("Statistiques")])],1),_c('b-row',{staticClass:"justify-content-center mx-1"},[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('h5',[_vm._v("Filtrer par date / période")]),_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.config},model:{value:(_vm.rangeDate),callback:function ($$v) {_vm.rangeDate=$$v},expression:"rangeDate"}})],1)],1),_c('b-col',{staticClass:"mt-2",attrs:{"md":"3"}},[_c('b-button',{attrs:{"disabled":_vm.isRangeStatisticsLoading,"variant":"primary"},on:{"click":function($event){_vm.rangeDate = null;
          _vm.filterIsActive = false;}}},[_vm._v(" "+_vm._s(_vm.isRangeStatisticsLoading ? "Chargement..." : "Effacer")+" ")])],1)],1),_c('b-card-body',{staticClass:"statistics-body"},[_c('b-row',[_vm._l((Object.entries(_vm.statsData)),function(item,index){return _c('b-col',{key:index,staticStyle:{"height":"max-content !important"},attrs:{"lg":"4","sm":"6"}},[_c('statistic-card-horizontal',{attrs:{"icon":item[0]==='numberOfActiveContracts' ? 'PowerIcon':
            item[0]==='numberOfCompletedPunctualOrder' ? 'CheckIcon':
            item[0]==='numberOfEmployeesDeployed' ? 'UserIcon':
            item[0]==='numberOfFinishedContract' ? 'ShieldOffIcon':
            item[0]==='numberOfPunctualOrder' ? 'RotateCwIcon':
            item[0]==='numberOfRecurringOrderBroughtByCC' ? 'RepeatIcon':
            item[0]==='numberOfRegisteredCustomers' ? 'UsersIcon':
            item[0]==='numberOfRegisteredPros' ? 'UsersIcon':
            item[0]==='numberOfSuspendedContract' ? 'DeleteIcon':
            item[0]==='numberOfEmployeesRegistered' ? 'UserIcon':
            item[0]==='numberOfEmployeesValidated' ? 'UserCheckIcon':
            item[0]==='numberOfEmployeesWhoQuitTheirJobs'? 'UserMinusIcon' :
            item[0]==='numberOfRecurringOrder'? 'RepeatIcon':
            '',"color":"info","statistic":item[1].toLocaleString(),"statistic-title":item[0]==='numberOfActiveContracts' ? 'Contrat(s) actif(s)':
            item[0]==='numberOfCompletedPunctualOrder' ? 'Commande(s) ponctuelle(s) terminée(s) ':
            item[0]==='numberOfEmployeesDeployed' ? 'Employé(s) déployé(s)':
            item[0]==='numberOfFinishedContract' ? 'Contrat(s) clôturé(s)':
            item[0]==='numberOfPunctualOrder' ? 'Commande(s) ponctuelle(s)':
            item[0]==='numberOfRecurringOrderBroughtByCC' ? 'Commande(s) récurrente(s) de la CC':
            item[0]==='numberOfRegisteredCustomers' ? 'Client(s) inscrit(s)':
            item[0]==='numberOfRegisteredPros' ? 'Pro(s) inscrit(s)':
            item[0]==='numberOfSuspendedContract' ? 'Contrat(s) résilié(s)':
            item[0]==='numberOfEmployeesRegistered' ? 'Employé(s) enregistré(s)':
            item[0]==='numberOfEmployeesValidated' ? 'Employé(s) validé(s)':
            item[0]==='numberOfEmployeesWhoQuitTheirJobs'? 'Employé(s) désisté(s)' :
            item[0]==='numberOfRecurringOrder'? 'Commande(s) récurrente(s)':
            ''}})],1)}),_vm._l((_vm.statsData),function(item){return _c('b-col',{key:item.subtitle,class:item.customClass,attrs:{"xl":"4","lg":"4","md":"4","sm":"6","cols":"12"}},[_c('b-media',{attrs:{"no-body":""}},[_c('b-media-aside',{staticClass:"mr-2"},[_c('b-avatar',{attrs:{"size":"48","variant":item.color}},[_c('feather-icon',{attrs:{"size":"24","icon":item.icon}})],1)],1),_c('b-media-body',{staticClass:"my-auto"},[_c('h4',{staticClass:"font-weight-bolder font-medium-4 mb-0"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('b-card-text',{staticClass:"font-small-3 mb-0"},[_vm._v(" "+_vm._s(item.subtitle)+" ")])],1)],1)],1)})],2)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }