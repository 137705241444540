<template>
  <b-row
    v-if="getUser.role.slug === 'super-administrateur' ||
    getUser.role.slug === 'comptable' ||
    getUser.role.slug === 'assistante-administrative' ||
      getUser.role.slug === 'responsable-relation-client'
    "
  >
    <b-col cols="12">
      <b-card>
        <b-card-header>
          <b-card-title>CHIFFRE D'AFFAIRES</b-card-title>
        </b-card-header>
        <b-row class="justify-content-start mx-1 mb-2 mt-2">
          <b-col
            md="6"
          >
            <b-form-group>
              <h5>Filtrage</h5>
              <flat-pickr
                v-model="rangeDate"
                class="form-control"
                placeholder="Sélectionner une intervalle de date"
                :config="config"
              />
            </b-form-group>
          </b-col>
          <b-col
            sm="3"
            class="mt-2"
          >
            <b-button
              :disabled="isDateRangeStatisticsLoading || rangeDate == null"
              variant="primary"
              @click="resetDateFinanceStatistics()"
            >
              {{ isDateRangeStatisticsLoading ? "Chargement..." : "Effacer" }}
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col

              cols="12"
              md="6"
              class="justify-content-start mx-2 mb-2 mt-2"
            >
              <b-button
                variant="primary"
                @click="resetFinanceDateStatistics()"
              >
                <div v-if="isReLoadFinanceDate">
                  <span> Chargement ... </span>
                  <b-spinner small />
                </div>
                <span v-else class="text-nowrap font-medium-1">Actualiser</span>
              </b-button>
          </b-col>
  
      </b-row>
        <div
          v-if="isDateFinanceStatisticsLoading"
          class="d-flex justify-content-center"
        >
          <b-spinner
            variant="info"
            style="width: 3rem; height: 3rem;"
          />
        </div>

        <b-row
          v-else
          class="mb-2"
        >
          <b-col
            v-for="(item , index) in Object.entries(getDateFinanceStatistics)"
            :key="index"
            lg="4"
            sm="6"
            style="height:max-content !important;"
          >

            <statistic-card-horizontal
              icon="DollarSignIcon"
              color="success"
              :statistic="item[1].toLocaleString()"
              :statistic-title="
                item[0]"
            />
          </b-col>

        </b-row>

        <b-row class="justify-content-start mx-1 mb-2">
          <b-col
            md="6"
          >
            <b-form-group>
              <h5>Filtrage</h5>
              <v-select
                v-model="monthSelected"
                :options="monthOptions"
                class="invoice-filter-select w-100 mr-1"
                placeholder="Sélectionner le mois"
                :reduce="(item) => item.value"
              >
                <template #selected-option="{ label }">
                  <span class="text-truncate overflow-hidden">
                    {{ label }}
                  </span>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col
            md="3"
            class="mt-2"
          >
            <v-select
              v-model="yearSelected"
              :options="yearOptions"
              class="invoice-filter-select w-100 mr-1"
              placeholder="Sélectionner l'année"
              :reduce="(item) => item.value"
            >
              <template #selected-option="{ label }">
                <span class="text-truncate overflow-hidden">
                  {{ label }}
                </span>
              </template>
            </v-select>
          </b-col>
          <b-col
            md="3"
            class="mt-2"
          >
            <b-button
              :disabled="isMonthRangeStatisticsLoading || yearSelected === '' || monthSelected===''"
              variant="primary"
              @click="resetMonthFinanceStatistics()"
            >
              {{ isMonthRangeStatisticsLoading ? "Chargement..." : "Effacer" }}
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col

              cols="12"
              md="6"
              class="justify-content-start mx-2 mb-2 mt-2"
            >
              <b-button
                variant="primary"
                @click="resetFinanceMonthStatistics()"
              >
                <div v-if="isReLoadFinanceMonth">
                  <span> Chargement ... </span>
                  <b-spinner small />
                </div>
                <span v-else class="text-nowrap font-medium-1">Actualiser</span>
              </b-button>
          </b-col>
  
      </b-row>
        <div
          v-if="isMonthFinanceStatisticsLoading"
          class="d-flex justify-content-center"
        >
          <b-spinner
            variant="info"
            style="width: 3rem; height: 3rem;"
          />
        </div>

        <b-row
          v-else
          class="mb-2"
        >
          <b-col
            v-for="(item , index) in Object.entries(getMonthFinanceStatistics)"
            :key="index"
            lg="4"
            sm="6"
            style="height:max-content !important;"
          >

            <statistic-card-horizontal
              icon="DollarSignIcon"
              color="success"
              :statistic="item[1].toLocaleString()"
              :statistic-title="
                item[0]"
            />
          </b-col>

        </b-row>

        <hr />

        <b-row>
          <b-col class="justify-content-start mx-2 mb-2 mt-2"><h3>TOTAL</h3></b-col>
          
        </b-row>
        <div
          v-if="isMonthFinanceStatisticsLoading || isDateFinanceStatisticsLoading"
          class="d-flex justify-content-center"
        >
          <b-spinner
            variant="info"
            style="width: 3rem; height: 3rem;"
          />
        </div>

        <b-row
         v-else
          class="mb-2"
        >
          <b-col
            v-for="(item , index) in Object.entries(getAllStatisticsFinance)"
            :key="index"
            lg="4"
            sm="6"
            style="height:max-content !important;"
          >

            <statistic-card-horizontal
              icon="DollarSignIcon"
              color="success"
              :statistic="item[1]"
              :statistic-title="
                item[0]"
            />
          </b-col>

        </b-row>

      </b-card>
    </b-col>

  </b-row>

  <div v-else>
    <not-authorized />
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  // BCardText,
  BCardTitle,
  // BCardSubTitle,
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  // BAlert,
  BTable,
  BMedia,
  BAvatar,
  // BDropdown,
  // BDropdownItem,
  BPagination,
  BSpinner,
} from 'bootstrap-vue'

// import VueApexCharts from 'vue-apexcharts'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import apexChatData from './apexChartData'
import StatisticsCard from './StatisticsCard.vue'
import { json } from 'body-parser'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    // VueApexCharts,
    // BAlert,
    BCardHeader,
    // BCardText,
    BCardTitle,
    // BCardSubTitle,
    BFormInput,
    BFormGroup,
    BTable,
    BMedia,
    BAvatar,
    // BDropdown,
    // BDropdownItem,
    BPagination,
    BSpinner,
    flatPickr,
    StatisticCardHorizontal,
    vSelect,
    // ToastificationContent,
    StatisticsCard,
  },
  data() {
    return {
      apexChatData,
      isReLoadFinanceDate: false,
      isReLoadFinanceMonth: false,
      invoices: [],
      isDateRangeStatisticsLoading: false,
      isMonthRangeStatisticsLoading: false,
      rangeDate: null,
      isLoading: true,
      commissioonsPerPeriod: null,
      proAmountPerPeriod: null,
      turnoverPerPeriod: null,
      totalAmountOfWagesPaid: null,
      totalAmountOfUnpaidWages: null,
      totalAmountOfWagesPaidByCustomers: null,
      turnoverGrowthRate: null,
      oneTimeOrderRevenueGrowthRate: null,
      growthRateOfNumberOfRegisteredProfessionals: null,
      growthRateOfNumberOfValidatedProfessionals: null,
      growthRateOfNumberOfRegisteredEmployees: null,
      growthRateOfNumberOfValidatedEmployees: null,
      employeesDropoutRate: null,
      customerGrowthRate: null,
      growthRateOfNumberOfCustomersInvitedByCC: null,
      placementFees: null,
      monthSelected: '',
      yearSelected: '',
      config: { mode: 'range' },

      monthOptions: [
        {
          label: 'Janvier',
          value: 'janvier',
        },
        {
          label: 'Février',
          value: 'février',
        },
        {
          label: 'Mars',
          value: 'mars',
        },
        {
          label: 'Avril',
          value: 'avril',
        },
        {
          label: 'Mai',
          value: 'mai',
        },
        {
          label: 'Juin',
          value: 'juin',
        },
        {
          label: 'Juillet',
          value: 'juillet',
        },
        {
          label: 'août',
          value: 'août',
        },
        {
          label: 'Septembre',
          value: 'septembre',
        },
        {
          label: 'Octobre',
          value: 'octobre',
        },
        {
          label: 'Novembre',
          value: 'novembre',
        },
        {
          label: 'Décembre',
          value: 'décembre',
        },
      ],
      tableColumns: [
        { key: 'profile_image', label: 'Professionnels', sortable: true },
        { key: 'name', label: 'Service', sortable: true },
        { key: 'amountearned', label: 'Bénéfice', sortable: true },
      ],
      searchQuery: '',
      perPage: 10,
      totalInvoices: 0,
      currentPage: 1,
      perPageOptions: [10, 25, 50, 100],
      sortBy: 'id',
      isSortDirDesc: true,
      avatarText,
    }
  },
  computed: {
    ...mapGetters('services', [
      'getServices',
      'getMostRequestedServices',
      'getRecurringServices',
    ]),
    ...mapGetters('auth', ['getUser']),
    ...mapGetters('customers', ['getCustomers']),
    ...mapGetters('orders', ['getOrders', 'getDirectOrders']),

    ...mapGetters('professionals', [
      'getProfessionals',
      'getBestProfessionals',
      'getProfessionalsPerServicesAndAddress',
      'getEmployees',
    ]),
    ...mapGetters('statistics', [
      'getProAmount',
      'getProfessionalsAmountPerServices',
      'getCommissions',
      'getCustomerConversion',
      'getSatisfiedOrder',
      'getUnsatisfiedOrder',
      'getStatistics',
      'getDateFinanceStatistics',
      'getMonthFinanceStatistics',
    ]),
    ...mapGetters([
      'isCommissionLoading',
      'isProAmountLoading',
      'isServicesLoading',
      'isRecurringServicesLoading',
      'isCustomerCommisionLoading',
      'isProAmountPerServiceLoading',

      'isCustomersLoading',
      'isReferredCustomersLoading',
      'isOrdersLoading',
      'isDirectOrdersLoading',
      'isProfessionalsLoading',
      'isSatisfiedOrderLoading',
      'isUnsatisfiedOrderLoading',
      'isEmployeesLoading',
      'isStatisticsLoading',
      'isDateFinanceStatisticsLoading',
      'isMonthFinanceStatisticsLoading',
    ]),
    getAllFinanceStatistics(){
      let allFinance = {}
     allFinance = Object.assign( {}, this.getMonthFinanceStatistics, this.getDateFinanceStatistics )
     return allFinance
    },
    getCommission(){
      let commision = 0
      commision = this.getAllFinanceStatistics["Commissions commandes récurrentes"]+ this.getAllFinanceStatistics["Commissions commandes Business récurrentes"] + this.getAllFinanceStatistics["Commissions commandes ponctuelles"] + this.getAllFinanceStatistics["Frais de placement"]
      return commision
    },

    getTotalComission(){
      let totalCommission = 0
      totalCommission = this.getCommission - this.getReductionAmount
      return totalCommission
    },

    getCustomerAmount(){
      let customer_amount = 0
      customer_amount = this.getAllFinanceStatistics["Montant payé par les clients commandes ponctuelles"] + this.getAllFinanceStatistics["Montant payé par les clients commandes Business récurrentes"]+ this.getAllFinanceStatistics["Montant payé par les clients commandes récurrentes"]
      return customer_amount
    },

    getPrestataireAmount(){
      let prestataire_amount = 0
      prestataire_amount = this.getAllFinanceStatistics["Montant payé aux employés commandes récurrentes"] + this.getAllFinanceStatistics["Montant payé par les clients commandes Business récurrentes"] + this.getAllFinanceStatistics["Gain total des pros"]
      return prestataire_amount
    },

    getReductionAmount(){
      let reduction_amount = 0
      reduction_amount = this.getAllFinanceStatistics["Montant total de réduction frais de placement"] + this.getAllFinanceStatistics["Réduction commission commandes récurrentes"]
      return reduction_amount
    },

    /* getAIBAmount(){
      let aib_amount = 0
      aib_amount = this.getAllFinanceStatistics["Montant total AIB"] + this.getAllFinanceStatistics["Montant total AIB commandes Business récurrentes"]
      return aib_amount
    }, */

    getVPSITSAmount(){
      let vps_its_amount = 0
      vps_its_amount = this.getAllFinanceStatistics["Montant total VPS et ITS Commandes Business récurrentes"] + this.getAllFinanceStatistics["Montant total VPS et ITS commandes récurrentes"]
      return vps_its_amount
    },

    getAssuranceAmount(){
      let assurance_amount = 0
      assurance_amount = this.getAllFinanceStatistics["Montant total Assurance commandes Business Récurrentes"] + this.getAllFinanceStatistics["Montant total Assurance commandes récurrentes"]
      return assurance_amount
    },

    getCnssAmount(){
      let cnss_amount = 0
      cnss_amount = this.getAllFinanceStatistics["Montant total CNSS commandes Business Récurrentes"] + this.getAllFinanceStatistics["Montant total CNSS commandes récurrentes"]
      return cnss_amount
    },

    getAllStatisticsFinance(){
      let financeStat = {}
      financeStat = {"Total commission": this.getTotalComission, "Total des montants payés par les clients": this.getCustomerAmount, "Total des montants reçus par les prestataires": this.getPrestataireAmount, "Total des réductions sur les commandes": this.getReductionAmount , "Total des VPS et ITS": this.getVPSITSAmount, "Total des Assurances": this.getAssuranceAmount, "Total des Cnss": this.getCnssAmount}
      return financeStat
    },
    yearOptions() {
      const data = []
      for (let i = 2021; i <= Number((new Date()).getFullYear().toString()); i++) {
        data.push({
          label: i.toString(),
          value: i.toString(),
        })
      }
      return data
    },
  },
  watch: {

    rangeDate(val) {
      if (val != null && val.split('to').length === 2) {
        // this.applyGetRangeStatisticsAction(val)
        this.applyGetDateFinanceRangeStatisticsAction(val)
      }
    },
    monthSelected(val) {
      if (val != null && this.yearSelected !== '') {
        this.applyGetMonthFinanceRangeStatisticsAction()
      }
    },
    yearSelected(val) {
      if (val != null && this.monthSelected !== '') {
        this.applyGetMonthFinanceRangeStatisticsAction()
      }
    },
  },
  created() {
    console.log(this.getAllFinanceStatistics["Frais de placement"])
    // if (this.isProAmountPerServiceLoading === false) {
    //   this.refetchData()
    // }
  },
  methods: {
    ...mapActions('statistics', [
      'getMonthFinanceStatisticsAction',
      'getDateFinanceRangeStatisticsAction',
      'getDateFinanceStatisticsAction',
      'getMonthFinanceRangeStatisticsAction',
    ]),

    resetDateFinanceStatistics() {
      this.$store.commit('SET_IS_DATE_FINANCE_STATISTICS_LOADING', true)
      this.getDateFinanceStatisticsAction()
        .then(() => {
          this.rangeDate = null
          this.$store.commit('SET_IS_DATE_FINANCE_STATISTICS_LOADING', false)
        })
        .catch(() => {
          this.rangeDate = null
          this.$store.commit('SET_IS_DATE_FINANCE_STATISTICS_LOADING', false)
        })
    },

    resetFinanceDateStatistics() {
      this.isReLoadFinanceDate = true
      this.$store.commit('SET_IS_DATE_FINANCE_STATISTICS_LOADING', true)
      this.getDateFinanceStatisticsAction()
        .then(() => {
          this.rangeDate = null
          this.isReLoadFinanceDate = false
          this.$store.commit('SET_IS_DATE_FINANCE_STATISTICS_LOADING', false)
        })
        .catch(() => {
          this.rangeDate = null
        this.isReLoadFinanceDate = false
          this.$store.commit('SET_IS_DATE_FINANCE_STATISTICS_LOADING', false)
        })
    },

    resetMonthFinanceStatistics() {
      this.$store.commit('SET_IS_MONTH_FINANCE_STATISTICS_LOADING', true)
      this.getMonthFinanceStatisticsAction()
        .then(() => {
          this.yearSelected = ''
          this.monthSelected = ''
          this.$store.commit('SET_IS_MONTH_FINANCE_STATISTICS_LOADING', false)
        })
        .catch(() => {
          this.yearSelected = ''
          this.monthSelected = ''
          this.$store.commit('SET_IS_MONTH_FINANCE_STATISTICS_LOADING', false)
        })
    },

    resetFinanceMonthStatistics() {
      this.isReLoadFinanceMonth= true
      this.$store.commit('SET_IS_MONTH_FINANCE_STATISTICS_LOADING', true)
      this.getMonthFinanceStatisticsAction()
        .then(() => {
          this.yearSelected = ''
          this.monthSelected = ''
          this.isReLoadFinanceMonth= flase
          this.$store.commit('SET_IS_MONTH_FINANCE_STATISTICS_LOADING', false)
        })
        .catch(() => {
          this.yearSelected = ''
          this.monthSelected = ''
          this.isReLoadFinanceMonth= false
          this.$store.commit('SET_IS_MONTH_FINANCE_STATISTICS_LOADING', false)
        })
    },


    applyGetDateFinanceRangeStatisticsAction(val) {
      const payload = {
        start_date: val.split('to')[0],
        end_date: val.split('to')[1],
      }
      this.isDateRangeStatisticsLoading = true
      this.$store.commit('SET_IS_DATE_FINANCE_STATISTICS_LOADING', true)
      this.getDateFinanceRangeStatisticsAction(payload)
        .then(response => {
          this.isDateRangeStatisticsLoading = false
          this.$store.commit('SET_IS_DATE_FINANCE_STATISTICS_LOADING', false)
        })
        .catch(error => {
          console.log(error.response.status)
          this.isDateRangeStatisticsLoading = false
          this.$store.commit('SET_IS_DATE_FINANCE_STATISTICS_LOADING', false)
          if (error.response.status !== 422) {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Erreur',
                  icon: 'AlertTriangleIcon',
                  text: 'Erreur lors du filtrage, Réessayer!',
                  variant: 'danger',
                },
              },
              {
                position: 'top-center',
              },
            )
          } else {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Erreur',
                  icon: 'AlertTriangleIcon',
                  text: error.response.data.message,
                  variant: 'danger',
                },
              },
              {
                position: 'top-center',
              },
            )
          }
        })
    },
    applyGetMonthFinanceRangeStatisticsAction() {
      const payload = {
        month: this.monthSelected,
        year: this.yearSelected,
      }
      this.isMonthRangeStatisticsLoading = true
      this.$store.commit('SET_IS_MONTH_FINANCE_STATISTICS_LOADING', true)
      this.getMonthFinanceRangeStatisticsAction(payload)
        .then(response => {
          this.isMonthRangeStatisticsLoading = false
          this.$store.commit('SET_IS_MONTH_FINANCE_STATISTICS_LOADING', false)
        })
        .catch(error => {
          console.log(error.response.status)
          this.isMonthRangeStatisticsLoading = false
          this.$store.commit('SET_IS_MONTH_FINANCE_STATISTICS_LOADING', false)
          if (error.response.status !== 422) {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Erreur',
                  icon: 'AlertTriangleIcon',
                  text: 'Erreur lors du filtrage, Réessayer!',
                  variant: 'danger',
                },
              },
              {
                position: 'top-center',
              },
            )
          } else {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Erreur',
                  icon: 'AlertTriangleIcon',
                  text: error.response.data.message,
                  variant: 'danger',
                },
              },
              {
                position: 'top-center',
              },
            )
          }
        })
    },

  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
